<template>
  <div class="columns">
    <div class="column">
      <span class="label">
        <span>Filters</span>
        <a
          class="icon is-size-6 has-text-info"
          :title="filtersTooltip"
          v-tippy="{ placement: 'right' }"
          v-if="permissions.valuationCertificate"
        >
          <i class="fal fa-info-circle" />
        </a>
      </span>
      <div class="field">
        <div class="filter-menu-container">
          <div
            v-for="(property, index) in properties"
            :key="index"
            class="filter"
          >
            <button
              class="button is-size-7 has-text-weight-semibold"
              :class="[
                activeMenu === property.type
                  ? 'is-dark'
                  : includes(activeFilters, property.type)
                  ? 'is-dark is-outlined'
                  : 'has-text-dark is-outlined'
              ]"
              :disabled="loading"
              @click="toggleMenu(property.type)"
            >
              <span>{{ property.label }}</span>
            </button>
            <div />
            <SimilarListingsFiltersDropdown
              v-if="activeMenu && property.type === activeMenu"
              v-bind="{ property }"
            />
          </div>
          <button
            class="button is-text has-text-info is-size-7 is-outlined"
            :disabled="loading"
            @click="clearFilters"
          >
            <span>Reset all</span>
          </button>
          <SimilarListingsStockToggle v-if="false && dealerIds.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { includes } from 'lodash'
export default {
  name: 'SimilarListingsControls',
  components: {
    SimilarListingsStockToggle: () =>
      import(
        'modules/companion/components/similar-listings/SimilarListingsStockToggle'
      ),
    SimilarListingsFiltersDropdown: () =>
      import('../similar-listings/filters/SimilarListingsFiltersDropdown')
  },
  computed: {
    properties: () => [
      {
        type: 'listing',
        label: 'Listing type',
        component: 'checkbox',
        icon: 'fa-list-ul'
      },
      {
        type: 'dealer',
        label: 'Seller type',
        component: 'checkbox',
        icon: 'fa-building'
      },
      { type: 'year', label: 'Registration', component: 'year' },
      { type: 'mileage', label: 'Mileage', component: 'mileage' },
      {
        type: 'colour',
        label: 'Colour',
        component: 'checkbox',
        icon: 'fa-paint-brush'
      },
      { type: 'distance', label: 'Distance', component: 'distance' },
      { type: 'keyword', label: 'Options', component: 'keyword' }
    ],
    filtersTooltip() {
      return 'Please note, selected filters will be reflected in the valuations certificate'
    },
    ...mapState({
      activeMenu: state => state.companion.similarListings.filterBy.activeMenu
    }),
    ...mapGetters({
      activeFilters: 'companion/similarListings/filterBy/active',
      loading: 'companion/similarListings/loading',
      dealerIds: 'auth/dealerIds',
      permissions: 'auth/permissions'
    })
  },
  methods: {
    includes(source, key) {
      return includes(source, key)
    },
    toggleMenu(filter) {
      this.$mxp.track(`companion_select_${filter}_filter`)
      this.$store.commit(
        'companion/similarListings/filterBy/toggleMenu',
        filter
      )
    },
    clearFilters() {
      this.$mxp.track('companion_reset_similar_listing_filters')
      this.$store.dispatch('companion/similarListings/filterBy/clearFilters')
      this.$store.commit(
        'companion/similarListings/filterBy/distance/clearLocation'
      )
      this.$store.dispatch('companion/similarListings/clearAndFetch')
    }
  }
}
</script>

<style lang="sass" scoped>
.filter-menu-container
  position: relative
  display: inline-flex
  align-items: center
  flex-wrap: wrap
  justify-content: flex-start
  .button
    margin-right: 0.5rem
    margin-bottom: 0.5rem
  .filter
    position: relative
.filter-menu-dropdown
  position: absolute
  left: 0
  background: #fff
  z-index: 2
  min-width: 26rem
  max-width: 48rem
  border: 1px solid #E9E9E9
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
  @media (max-width: 768px)
    min-width: 100%
    position: fixed
    top: 0
    left: 0
    height: 100%
    z-index: 5
</style>
